<template>
  <AppBar>
    <div class="btnBox">
      <el-button
        type="info"
        round
        size="small"
        @click="toExamine(0,0)"
        v-if="!power"
        >全部</el-button
      >
      <el-button type="info" round size="small" @click="toExamine(2,1)"
        >待审核</el-button
      >
      <el-button type="danger" round size="small" @click="toExamine(3,2)"
        >未通过审核</el-button
      >
      <!-- <el-button type="success" round size="small">已审核</el-button> -->
    </div>
    <ListData
      isApproval
      :tableData="tableData"
      @RefreshData="RefreshData"
      isBtn="3"
    >
      <template #default="{ project, data }">
        <el-button
          type="text"
          icon="el-icon-edit-outline"
          @click="check(project, data)"
        ></el-button>
      </template>
    </ListData>
    <el-pagination
      style="margin-top: 20px"
      :hide-on-single-page="true"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="20"
      background
      layout="total, prev, pager, next"
      :total="total"
    >
    </el-pagination>
    <Dialog
      v-if="power"
      :dialogVisible="dialogVisible"
      :id="id"
      :state="dialogState"
      @dialogVisible="dialogVisible = false"
    />
  </AppBar>
</template>
<style lang="less" scoped>
.btnBox {
  margin-bottom: 20px;
  text-align: right;
  button {
    padding: 8px 25px;
    font-size: 14px;
  }
}
</style>
<script>
import AppBar from "../../components/AppBar";
import ListData from "../../components/ListData";
import Dialog from "./Dialog";
export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      id: 17,

      dialogState: 0,
      reviewParams: {
        page: 1,
        state: 0,
      },
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    power() {
      return window.sessionStorage.getItem("role_id") == "1";
    },
  },
  methods: {
    // 分页
    handleCurrentChange(page) {
      this.reviewParams.page = page;
      this.toExamine();
    },
    toExamine(examineId, su) {
      console.log(examineId);
      this.tableData = [];
      if (examineId != undefined) {
        this.reviewParams.page = 1;
        this.currentPage = 1;
        this.reviewParams.state = examineId;
      }
      if (sessionStorage.getItem("role_id") == 1) {
        this.$common.getInterface(
          "/api/v1.projects/getAdminList",
          {
            state: examineId != undefined ? examineId : this.reviewParams.state,
            page: this.reviewParams.page,
          },
          this,
          (res1) => {
            this.tableData = res1.data.data;
          }
        );
        this.reviewParams.state =
          examineId != undefined ? examineId : this.reviewParams.state;
      } else {
        this.$common.getInterface(
          "/api/v1.projects/myChecks",
          // {
          //   state:
          //     examineId != undefined
          //       ? examineId == 0
          //         ? 0
          //         : examineId - 1
          //       : this.reviewParams.state,
          //   page: this.reviewParams.page,
          // },
          { state: su },
          this,
          (res) => {
            res.data.data.forEach((item) => {
              this.tableData.push(item);
            });
            // let id = sessionStorage.getItem("id");
            // this.reviewParams.state =
            //   examineId != undefined
            //     ? examineId == 0
            //       ? 0
            //       : examineId - 1
            //     : this.reviewParams.state;
            // res.data.data.forEach((item) => {
            //   let nowDate =
            //     new Date().getFullYear() +
            //     "/" +
            //     (new Date().getMonth() + 1) +
            //     "/" +
            //     new Date().getDate() +
            //     " 00:00:00";
            //   console.log(item.id_headman, item.id_headman_date, nowDate, id);
            //   if (
            //     (item.id_headman == id &&
            //       new Date(item.id_headman_date.replace(/-/g, "/")).getTime() >=
            //         new Date(nowDate).getTime()) ||
            //     item.id_lead == id
            //   ) {
            //     console.log(324234347);
            //     if (examineId == 0) {
            //       this.tableData.push(item);
            //     } else {
            //       if (item.check_state == examineId - 2) {
            //         this.tableData.push(item);
            //       }
            //     }
            //   }
            // });
          }
        );
      }
    },
    // 分配组长画师后刷新列表数据
    RefreshData() {
      if (sessionStorage.getItem("role_id") == 1) {
        this.$common.getInterface(
          "/api/v1.projects/getAdminList",
          { state: 2 },
          this,
          (res1) => {
            // console.log(res);
            // this.tableData = res1.data.data;
            this.$common.getInterface(
              "/api/v1.projects/getAdminList",
              { state: 3 },
              this,
              (res2) => {
                this.tableData = res1.data.data.concat(res2.data.data);
              }
            );
          }
        );
      } else {
        this.$common.getInterface(
          "/api/v1.projects/myChecks",
          { id: 4 },
          this,
          (res) => {
            console.log(res);
            this.tableData = res.data.data;
          }
        );
      }
    },
    check(id, data) {
      if (sessionStorage.getItem("role_id") == "1") {
        this.dialogState = (data && data.state) || 0;
        this.id = id;
        this.dialogVisible = true;
      } else {
        sessionStorage.setItem("urlName", this.$route.name);
        this.$router.push({ path: `/audit/${id}` });
      }
    },
  },
  created() {
    if (sessionStorage.getItem("role_id") == 1) {
      this.$common.getInterface(
        "/api/v1.projects/getAdminList",
        { state: 2 },
        this,
        (res1) => {
          // console.log(res);
          // this.tableData = res1.data.data;
          this.$common.getInterface(
            "/api/v1.projects/getAdminList",
            { state: 3 },
            this,
            (res2) => {
              // 翻转审核列表
              var arr2 = reverse1(res2.data.data);
              console.log(arr2);
              this.tableData = res1.data.data.concat(arr2);
              this.total = this.tableData.length;
            }
          );
        }
      );
    } else {
      this.$common.getInterface(
        "/api/v1.projects/myChecks",
        { state: 0 },
        this,
        (res) => {
          res.data.data.forEach((item) => {
            this.tableData.push(item);
          });
          // let id = sessionStorage.getItem("id");
          // res.data.data.forEach((item) => {

          //   let nowDate =
          //     new Date().getFullYear() +
          //     "/" +
          //     (new Date().getMonth() + 1) +
          //     "/" +
          //     new Date().getDate() +
          //     " 00:00:00";
          //   console.log(id);

          //   if (
          //     (item.id_headman == id &&
          //       new Date(item.id_headman_date.replace(/-/g, "/")).getTime() >=
          //         new Date(nowDate).getTime()) ||
          //     item.id_lead == id
          //   ) {
          //     if (item.check_state != 2) {
          //       this.tableData.push(item);
          //     } else {
          //       this.tableData.push(item)
          //       // if (item.check_state == 1 && item.check.state == 0) {
          //       //   this.tableData.push(item);
          //       // }
          //     }
          //   }
          // });
          this.total = this.tableData.length;

          // this.tableData = [];
          // res.data.data.forEach(item => {
          //   console.log(item);

          //   if(  item.check_state != 2) {
          //     this.tableData.push(item);
          //   }else {
          //     if(item.check_state == 1 && item.check.state == 0) {
          //       this.tableData.push(item);
          //     }
          //   }
          // })
        }
      );
    }
  },
  components: {
    AppBar,
    ListData,
    Dialog,
  },
};
function reverse1(array) {
  var newArr = [];
  for (var i = array.length - 1; i >= 0; i--) {
    newArr[newArr.length] = array[i];
  }
  return newArr;
}
</script>

